import { INLINES } from "@contentful/rich-text-types";
import cn from "classnames";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import parse from "html-react-parser";

import TrackableLink from "../../atoms/trackable-link/TrackableLink";
import ContactLinks from "../../molecules/contact-links/ContactLinks";
import Details from "../../molecules/details/Details";
import SectionTitle from "../../molecules/section-title/SectionTitle";
import { countries, CountryCode } from "../../settings/countries";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";
import { shouldShowContactsLinks } from "../../utils/locale-configuration-utils";
import * as styles from "./Faqs.module.scss";

interface FaqItem {
  id: string;
  title: string;
  richContent: {
    raw: string;
    references: any[];
  };
}

interface FaqsProps {
  data: {
    title: string;
    faqItems: FaqItem[];
  };
  adoptionClassName?: string;
  countryCodeIso?: CountryCode;
  forceHideContactLinks?: boolean;
}

const Faqs = ({
  data: { title, faqItems },
  adoptionClassName,
  countryCodeIso = CountryCode.ES,
  forceHideContactLinks = false,
}: FaqsProps): JSX.Element => {
  const { ref } = useVisibilityTracker("faqs", {
    amount: 0.5,
  });

  return (
    <section ref={ref} className={cn(styles.faqs, adoptionClassName, "or-faqs")}>
      <SectionTitle title={parse(title)} adoptionClassName={styles.sectionTitle} />
      {!forceHideContactLinks && shouldShowContactsLinks(countryCodeIso) && (
        <ContactLinks
          contactMethods={countries[countryCodeIso].contactMethods}
          adoptionClassname={styles.contactLinks}
          trackingId="Faqs"
        />
      )}
      {faqItems.map(({ id, title, richContent }) => {
        const options = {
          renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
              return (
                <TrackableLink
                  openInNewWindow
                  extraEventProperties={{
                    id,
                    faqQuestion: title,
                  }}
                  eventSender="Faqs"
                  to={node.data.uri}
                >
                  {children}
                </TrackableLink>
              );
            },
          },
        };

        return (
          <Details key={id} title={title} trackingId={`faq-id-${id}`}>
            {renderRichText(richContent, options)}
          </Details>
        );
      })}
    </section>
  );
};

export const query = graphql`
  fragment FaqsFragment on ContentfulFaqsList {
    title
    faqItems {
      id
      title
      richContent {
        raw
      }
    }
    locale: node_locale
  }
`;

export default Faqs;
